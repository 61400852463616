<template>
  <div class="home">
    <img alt="App logo" src="../assets/logo.png" />
    <div class="div-home-componenet">
      <HomeComponent msg="PassWard" />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import HomeComponent from "@/components/HomeComponent.vue"; // @ is an alias to /src

@Options({
  components: {
    HomeComponent,
  },
})
export default class Home extends Vue {}
</script>

<style  scoped>
img {
  max-height: 120px;
  max-width: 120px;
}
</style>