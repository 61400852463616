<template>
  <div class="home">
    <h1>{{ msg }}</h1>
    <br />
    <p>
      PassWard is password manager app made to safely secure & access your
      credentials in conveinant way
    </p>
    <br />
    <h3>Available On</h3>
    <br />
    <a href="">
      <img src="../assets/app-store-badge.png" />
    </a>
    <a href=""> <img src="../assets/google-play-badge.png" /> </a>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    msg: String,
  },
})
export default class HelloWorld extends Vue {
  msg!: string;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 0px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

img {
  max-width: 200px;
}
</style>
