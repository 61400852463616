<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/privacy-policy">Privacy Policy</router-link>
  </div> -->
  <router-view />
</template>

<style>
* {
  margin: 4px;
  background-color: #1c1c1d;
}

#app {
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #e1e2e3;
  background-color: #1c1c1d;
  height: 100%;
  max-height: 100%;
}

#body {
  background-color: #1c1c1d;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  text-decoration: none;
  color: #3a82f7;
}

#nav a.router-link-exact-active {
  color: #e1e2e3;
}
</style>
